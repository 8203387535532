<script>
	import { scrollY } from 'svelte-window-stores/viewport';
	import { scrollTop } from 'svelte-scrolling';
</script>

<button
	class="btn btn-primary z-50"
	style="position: fixed; bottom: 1.25rem; right: 1.25rem; display: {$scrollY > 150 ? 'inline-block' : 'none'}"
	on:click={() => scrollTop()}
	><svg
		xmlns="http://www.w3.org/2000/svg"
		class="h-6 w-6"
		fill="none"
		viewBox="0 0 24 24"
		stroke="currentColor"
		stroke-width="2"
	>
		<path stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
	</svg>
</button>
