<script>
	import AnimationInOut from '$lib/components/AnimationInOut.svelte';
</script>

<div class="hero min-h-half-screen">
	<AnimationInOut>
		<div class="hero-content flex-col lg:flex-row-reverse">
			<img class="p-5" width="250" src="/images/governance.svg" alt="Governance" />
			<div class="max-w-lg">
				<h1 class="text-3xl lg:text-5xl font-bold capitalize lg:-mr-5 text-center lg:text-left">
					Community Governance
				</h1>
				<p class="pt-6 text-xl text-center lg:text-left">
					The $KNS token will be distributed to .kcc name holders.
				</p>
				<p class="pb-6 text-xl text-center lg:text-left">
					Token holders will govern the KNS DAO by voting on proposals.
				</p>
			</div>
		</div>
	</AnimationInOut>
</div>
