<script>
	import AnimationInOut from '$lib/components/AnimationInOut.svelte';
</script>

<div class="hero min-h-half-screen">
	<AnimationInOut>
		<div class="hero-content flex-col lg:flex-row-reverse">
			<img width="250" src="/images/blockchain.svg" alt="Blockchain" />
			<div class="max-w-lg">
				<h1 class="text-3xl lg:text-5xl capitalize font-bold text-center lg:text-left">
					Register once, own forever
				</h1>
				<p class="py-6 text-xl text-center lg:text-left">
					We don't believe in recurring subscription fees. Once you mint your .kcc name it's yours
					forever.
				</p>
			</div>
		</div>
	</AnimationInOut>
</div>
