<script>
	import AnimationInOut from '$lib/components/AnimationInOut.svelte';
</script>

<div class="hero min-h-half-screen">
	<AnimationInOut>
		<div class="hero-content flex-col lg:flex-row">
			<img class="p-5" width="250" src="/images/nft.svg" alt="NFT" />
			<div class="max-w-lg">
				<h1 class="text-3xl lg:text-5xl capitalize font-bold text-center lg:text-right">
					NFT-Powered Names
				</h1>
				<p class="py-6 text-xl text-center lg:text-right">
					The names our service provides are NFTs and can be traded on NFT marketplaces or gifted to
					your best friend!
				</p>
			</div>
		</div>
	</AnimationInOut>
</div>
