<script>
	import { scrollTo } from 'svelte-scrolling';
</script>

<div class="navbar bg-base-100">
	<div class="navbar-start">
		<div class="dropdown">
			<label tabindex="0" class="btn btn-ghost lg:hidden">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="h-5 w-5"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					><path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M4 6h16M4 12h8m-8 6h16"
					/></svg
				>
			</label>
			<ul
				tabindex="0"
				class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
			>
				<li><a href="https://docs.kuname.domains/">Docs</a></li>
				<li>
					<a href="https://kuname.notion.site/kuname/a7749b8ee3394328b008a3c733158812">Roadmap</a>
				</li>
				<li tabindex="0">
					<a>
						Social
						<svg
							class="fill-current"
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 24 24"
							><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg
						>
					</a>
					<ul class="p-2 bg-base-100">
						<li><a target="_blank" href="https://t.me/KuNameDomains">Telegram</a></li>
						<li><a target="_blank" href="https://twitter.com/KuNameDomains">Twitter</a></li>
						<li><a target="_blank" href="https://discord.gg/DGV9UFdTvT">Discord</a></li>
						<li><a target="_blank" href="https://github.com/KuNameDomains">GitHub</a></li>
					</ul>
				</li>
			</ul>
		</div>
		<a class="btn btn-ghost" href="/"
			><img width="100" src="/images/kuname-logo-text-white-67.svg" /></a
		>
	</div>
	<div class="navbar-center hidden lg:flex">
		<ul class="menu menu-horizontal p-0">
			<li><a href="https://docs.kuname.domains/">Whitepaper</a></li>
			<li>
				<a href="https://kuname.notion.site/kuname/a7749b8ee3394328b008a3c733158812">Roadmap</a>
			</li>
			<li tabindex="0">
				<a>
					Social
					<svg
						class="fill-current"
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						viewBox="0 0 24 24"
						><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg
					>
				</a>
				<ul class="p-2 bg-base-100">
					<li><a target="_blank" href="https://t.me/KuNameDomains">Telegram</a></li>
					<li><a target="_blank" href="https://twitter.com/KuNameDomains">Twitter</a></li>
					<li><a target="_blank" href="https://discord.gg/DGV9UFdTvT">Discord</a></li>
					<li><a target="_blank" href="https://github.com/KuNameDomains">GitHub</a></li>
				</ul>
			</li>
		</ul>
	</div>
	<div class="navbar-end">
		<a class="btn" href="/app">Launch app</a>
	</div>
</div>
