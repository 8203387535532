<script>
	import AnimationInOut from '$lib/components/AnimationInOut.svelte';
</script>

<div class="hero min-h-half-screen">
	<AnimationInOut>
		<div class="hero-content flex-col lg:flex-row">
			<img class="ml-0 lg:-ml-20" width="250" src="/images/web.svg" alt="Website Hosting" />
			<div class="max-w-lg">
				<h1 class="text-3xl lg:text-5xl lg:-mr-3 font-bold text-center lg:text-right">
					Your Web3 .kcc Domain
				</h1>
				<p class="pt-6 text-xl text-center lg:text-right">
					Launch your censorship-resistant website with a .kcc domain and host it for free on IPFS.
				</p>
			</div>
		</div>
	</AnimationInOut>
</div>
