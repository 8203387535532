<script>
	import { scrollTo } from 'svelte-scrolling';
	import { fly } from 'svelte/transition';
</script>

<div
	transition:fly={{ y: 200, duration: 500 }}
	class="hero min-h-screen"
	style="background-image: url(/images/hero-bg.png);"
>
	<div class="hero-overlay bg-opacity-10" />
	<div class="hero-content text-center text-neutral-content">
		<div class="max-w-lg xl:max-w-none">
			<h1 class="mb-5 text-5xl xl:text-7xl font-bold">NFT Names & Domains</h1>
			<p class="mb-5 text-primary text-xl">
				KuName Domains is the #1 name service provider on KCC.
			</p>
			<span class="btn btn-primary mt-10 w-64" use:scrollTo={'learn-more'}>Learn More</span>
		</div>
	</div>
</div>
