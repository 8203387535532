<script>
	import AnimationInOut from '$lib/components/AnimationInOut.svelte';
</script>

<div class="hero min-h-half-screen">
	<AnimationInOut>
		<div class="hero min-h-half-screen">
			<div class="hero-content flex-col lg:flex-row">
				<div class="avatar p-5">
					<div class="w-48 lg:w-52 rounded-full ring-4  lomen-ring-stroke">
						<img
							src="https://ipfs.kuswap.finance/ipfs/bafybeicy2wconk5ttijhpt3hgkabynrqpx5vfcvwhco3ocsmtgprtzgri4/6451.png"
							alt="Lomen NFT"
						/>
					</div>
				</div>
				<div class="max-w-lg">
					<h1 class="text-3xl lg:text-5xl font-bold text-center lg:text-right capitalize">
						Holding a Lomen NFT?
					</h1>
					<h1 class="text-3xl lg:text-5xl font-bold text-center lg:text-right capitalize">
						Look no further
					</h1>
					<p class="py-6 text-xl text-center lg:text-right">
						Get up to a 50% discount on purchases if you are a Lomen NFT holder. Discount
						automatically applied at checkout!
					</p>
				</div>
			</div>
		</div>
	</AnimationInOut>
</div>

<style>
	.lomen-ring-stroke {
		--tw-ring-opacity: 1;
		--tw-ring-color: #cccccc;
	}
</style>
