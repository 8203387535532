<script>
	import AnimationInOut from '$lib/components/AnimationInOut.svelte';
	import { scrollRef } from 'svelte-scrolling';
</script>

<section use:scrollRef={'learn-more'}>
	<div class="hero min-h-half-screen">
		<AnimationInOut>
			<div class="hero-content flex-col lg:flex-row-reverse">
				<img class="p-5 lg:p-0" width="250" src="/images/wallet.svg" alt="Wallet" />
				<div class="max-w-lg">
					<h1 class="text-3xl lg:text-5xl text-center lg:text-left font-bold">
						Your Web3 .kcc Wallet Username
					</h1>
					<p class="py-6 text-xl text-center lg:text-left">
						Get your own unique .kcc name and connect it to your wallet addresses on KCC. No
						more hard-to-remember addresses!
					</p>
				</div>
			</div>
		</AnimationInOut>
	</div>
</section>

<style>
</style>
