<footer class="footer footer-center p-4 bg-base-300 text-base-content">
	<div>
		<p>
			Copyright © 2022 - All right reserved by KuName Domains — <a
				class="link"
				href="https://docs.kuname.domains/legal/terms-of-use">Terms of Use</a
			>
		</p>
	</div>
</footer>
