<script>
	import Saos from 'saos';
</script>

<Saos
	animation={'roll-in-blurred-left 0.65s cubic-bezier(0.230, 1.000, 0.320, 1.000) both'}
	animation_out={'swirl-out-bck 0.6s ease-in both'}
	top={120}
	bottom={120}
>
	<slot />
</Saos>
